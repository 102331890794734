import { sanitizeLocale } from 'locale/sanitize';

import {
  attachElmPort,
  fetchModule,
  generateRelay,
  getEnvironment,
  getIframeUrl,
  getTranslations,
  popupCenter,
  tryParse,
  waitForPort,
} from './helpers';
import versions from './tmp/versions';
import { Module, SentryEvent } from './types';

const variants = new Map<string, string>(versions.variants as any);
const modules = new Map<string, Module>();

function getModule(variantName: string, requestedLocale: string): Module {
  const mod = variants.get(variantName);
  let locale = sanitizeLocale(requestedLocale);
  if (!mod) {
    throw new Error('Invalid variant.');
  }

  if (!versions.locales.includes(locale)) {
    console.warn(
      `Locale ${locale} not found for variant ${variantName}, defaulting to "en-us".`
    );

    locale = 'en-us';
  }
  const script = versions.example
    .replace('__NAME__', variantName)
    .replace('__LOCALE__', locale);

  if (!modules.has(script)) {
    modules.set(script, {
      script,
      name: mod,
      translations: versions.translations,
    });
  }

  return modules.get(script);
}

const mandatoryParams = [
  'relay',
  'client_id',
  'locale',
  'redirect_uri',
  'scope',
  'sl',
  'response_type',
];

document
  .querySelectorAll('meta[name="susi-sentry-preload"]')
  .forEach((meta: HTMLMetaElement) => {
    const forceStage = meta.dataset.stage === 'true';
    const locale = meta.dataset.locale;
    const mod = getModule(meta.content, locale);

    mod && fetchModule(mod, forceStage);
  });

class SentryWrapper extends HTMLElement {
  // ROOT
  private renderRoot: ShadowRoot;
  private isConstructed = false;

  // Configuration variables
  popup: boolean;

  // Temp state to detect changes
  private _authparams: Record<string, string>;
  private _config = {} as Record<string, string>;
  private _variant: string | null = null;

  private module!: Module;

  private _variantPromise: Promise<void> | null = null;

  constructor() {
    super();

    try {
      this.renderRoot = this.attachShadow({ mode: 'open' });
      this.popup = this.getAttribute('popup') === 'true';

      if (this.getAttribute('variant')) {
        this.setVariant(this.getAttribute('variant'));
      }
      this._authparams = {};
    } catch (e) {
      console.warn('SUSI Light - Missing parameters on init.', e);
    } // Treat initial values as optional
  }

  connectedCallback() {
    this.isConstructed = true;

    if (this.variant) {
      this.render();
    }
  }

  setVariant(value: string) {
    this.module = getModule(value, this.locale);

    this._variant = value;
    this._variantPromise = fetchModule(this.module!, this.isStage);

    if (this.isConstructed) {
      this.render();
    }
  }

  set variant(value: string) {
    this.setVariant(value);
  }

  get variant() {
    return this._variant ?? '';
  }

  get locale() {
    return this.authParams.locale;
  }

  get isStage() {
    return this.getAttribute('stage') === 'true';
  }

  set authParams(value: Record<string, string>) {
    this._authparams = value;
  }

  get authParams(): Record<string, string> {
    return this._authparams;
  }

  set config(value: Record<string, string>) {
    this._config = value;
  }

  get config(): Record<string, string> {
    return this._config;
  }

  get systemParams() {
    return {
      mode: Boolean(this.popup) ? 'popup' : 'redirect', // eslint-disable-line
    };
  }

  get appState() {
    const relay = this.authParams.relay ?? generateRelay();
    const params = { ...this.authParams, sl: window.origin, relay };

    const isMandatory = key => mandatoryParams.includes(key);

    const queryState = Object.fromEntries(
      Object.entries(params).filter(([key, val]) => val && isMandatory(key))
    );

    const authParams = Object.entries(params).map(([k, v]) => [
      k,
      v.toString(),
    ]);

    return {
      queryState,
      authParams,
      systemParams: this.systemParams,
      config: this.config,
    };
  }

  async initializeApplication(root: HTMLElement) {
    const env = getEnvironment(window.location.href);
    const translations = getTranslations(
      env,
      this.module,
      sanitizeLocale(this.locale)
    );

    const app = window.Elm[this.module.name].Main.init({
      node: root,
      flags: {
        ...this.appState,
        translations,
      },
    });

    if (app.ports) {
      attachElmPort<string>(app.ports, 'onOpenPopup', url => {
        popupCenter({ url, title: 'SUSI Light Login', w: '459', h: '768' });
      });

      attachElmPort<SentryEvent>(app.ports, 'onSentryEvent', event => {
        this.dispatchEvent(new CustomEvent(event.name, { detail: event.data }));
      });
    }

    return app;
  }

  static observedAttributes = ['authParams', 'config', 'stage', 'variant'];

  createShadow() {
    return this.attachShadow({ mode: 'closed' });
  }

  async render() {
    const env = getEnvironment(window.location.href, this.isStage);
    const iframeUrl = getIframeUrl(env);
    const portPromise = waitForPort(iframeUrl);
    const { client_id, relay } = this.appState.queryState;
    const isValidOrigin = this.authParams.st_valid_origin;

    function makeIframeUrl() {
      const url = new URL('/sentry/iframe/index.html', iframeUrl);
      url.searchParams.append('client_id', client_id);
      url.searchParams.append('relay', relay);
      if (isValidOrigin) {
        url.searchParams.append('st_valid_origin', 'true');
      }
      url.searchParams.append('asserted_origin', window.location.origin);

      return url.toString();
    }

    if (this.shadowRoot) {
      this.shadowRoot.innerHTML = `
        <style>
          :host {
            display: block;
          }

          :host * {
            box-sizing: border-box;
          }

          :host,
          #sentry-container,
          #sentry-container iframe {
            width: 100%;
            height: 100%;
            background: transparent;
            color-scheme: normal;
            border: 0;
          }

          #sentry-iframe {
            position: fixed;
            top: -10000px;
            left: -10000px;
          }
        </style>
        <section id="sentry-container">
          <section id="sentry"></sentry>
        </section>
        <iframe
          aria-hidden="true"
          id="sentry-iframe"
          height="1px" 
          width="1px" 
          src="${makeIframeUrl()}" />
    `;
    } else throw new Error('shadowRoot not available');

    try {
      const [port] = await Promise.all([portPromise, this._variantPromise]);
      const app = await this.initializeApplication(
        this.renderRoot.getElementById('sentry')!
      );

      port.onmessage = function (e) {
        try {
          const event = tryParse<SentryEvent>(e.data);
          const subEvent = tryParse<SentryEvent>(event.data);
          if (app.ports && event.name === 'query-state') {
            app.ports.onPopupMessage.send(subEvent);
          } else {
            console.log(e);
          }
        } catch (e) {
          console.error(e);
        }
      };
    } catch (e) {
      console.error(e);
      this.dispatchError();
    }
  }

  dispatchError(detail = { name: 'unrecoverable' }) {
    this.dispatchEvent(new CustomEvent('on-error', { detail }));
  }
}

// TODO: Handle tag name differential
window.customElements.define('susi-sentry-light', SentryWrapper);
