import { Module } from 'types';

export const ENV = {
  LOCAL: 'local',
  STAGE: 'stage',
  PROD: 'prod',
} as const;

export function getEnvironment(location: string, forceStage = false) {
  if (forceStage) {
    return ENV.STAGE;
  }

  if (location.includes('localhost.corp.adobe.com')) {
    return ENV.LOCAL;
  }

  if (location.includes('auth-light-sample.identity-stage.adobe.com')) {
    return ENV.STAGE;
  }

  return ENV.PROD;
}

export function getTranslations(env: string, mod: Module, locale: string) {
  if (env === ENV.LOCAL) {
    return mod.translations;
  }

  return window.ElmTranslations[mod.name][locale];
}

type ObjectValues<T> = T[keyof T];

export const MODULE_URL = {
  [ENV.LOCAL]: 'https://localhost.corp.adobe.com:8081',
  [ENV.STAGE]: 'https://auth-light.identity-stage.adobe.com/sentry',
  [ENV.PROD]: 'https://auth-light.identity.adobe.com/sentry',
};

type ModuleKey = ObjectValues<typeof MODULE_URL>;

export const getModuleUrl = (env: ModuleKey) => MODULE_URL[env];

export const IFRAME_URL = {
  [ENV.LOCAL]: 'https://localhost.corp.adobe.com:25000',
  [ENV.STAGE]: 'https://auth-light.identity-stage.adobe.com',
  [ENV.PROD]: 'https://auth-light.identity.adobe.com',
};

type IframeKey = ObjectValues<typeof IFRAME_URL>;

export const getIframeUrl = (env: IframeKey) => IFRAME_URL[env];

const MODULE_PROMISES = new Map<Module, Promise<null>>();
export async function fetchModule(
  mod: Module,
  forceStage = false
): Promise<null> {
  if (!MODULE_PROMISES.has(mod)) {
    const promise = fetchModuleScript(mod, forceStage);
    MODULE_PROMISES.set(mod, promise);

    return promise;
  } else {
    return MODULE_PROMISES.get(mod);
  }
}

export function fetchModuleScript(
  mod: Module,
  forceStage = false
): Promise<null> {
  return new Promise((resolve, reject) => {
    if (window.Elm && window.Elm[mod.name]) {
      return resolve(null);
    }

    const env = getEnvironment(window.location.href, forceStage);
    const url = getModuleUrl(env);

    const script = document.createElement('script');
    script.src = `${url}/${mod.script}`;
    document.head.appendChild(script);

    script.onload = resolve as any;
    script.onerror = reject;
  });
}

export function waitForPort(expectedOrigin: string): Promise<MessagePort> {
  return new Promise((resolve, _) => {
    function portWaiter(e: MessageEvent) {
      if (!expectedOrigin.includes(e.origin)) return;
      resolve(e.ports[0]);
      window.removeEventListener('message', portWaiter);
    }
    window.addEventListener('message', portWaiter);
  });
}

export function popupCenter({ url, title, w, h }: Record<string, string>) {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const left = (width - parseInt(w)) / 2 + dualScreenLeft;
  const top = (height - parseInt(h)) / 2 + dualScreenTop;

  const openedWindow = window.open(
    url,
    title,
    `
    menubar=0,
    resizable=0,
    scrollbars=no,
    width=${w}, 
    height=${h}, 
    top=${top}, 
    left=${left}
    `
  );

  return openedWindow;
}

export function tryParse<T>(value: unknown) {
  try {
    return JSON.parse(value as string) as T;
  } catch (e) {
    return value as T;
  }
}

export function attachElmPort<ExpectedData>(
  ports: Record<string, any> | undefined,
  portName: string,
  cb: (data: ExpectedData) => void
) {
  if (
    ports &&
    Object.prototype.hasOwnProperty.call(ports, portName) &&
    typeof ports[portName] === 'object' &&
    typeof ports[portName].subscribe === 'function'
  ) {
    const port = ports[portName];
    port.subscribe((data: any) => cb(tryParse(data) as ExpectedData));
  } else {
    console.error('No port: ' + portName);
  }
}

export function generateRelay(): string {
  const randomBytes = new Uint8Array(16);
  crypto.getRandomValues(randomBytes);
  randomBytes[6] = (randomBytes[6] & 0x0f) | 0x40; // Set version to 4 (0100)
  randomBytes[8] = (randomBytes[8] & 0x3f) | 0x80; // Set variant to 1 (10)

  const toHex = (n: number) => n.toString(16).padStart(2, '0');
  const byteToHex = Array.from(randomBytes, toHex);
  const relay = [
    ...byteToHex.slice(0, 4),
    '-',
    ...byteToHex.slice(4, 6),
    '-',
    ...byteToHex.slice(6, 8),
    '-',
    ...byteToHex.slice(8, 10),
    '-',
    ...byteToHex.slice(10),
  ].join('');

  return relay;
}

function setPos(
  target: HTMLElement,
  { top, left, right, bottom }: Record<string, number>
) {
  target.style.left = left + 'px';
  target.style.top = top + 'px';
  target.style.right = right + 'px';
  target.style.bottom = bottom + 'px';
}

export function setModalModeInitial(container: HTMLElement) {
  const { top, left, width, height } = container.getBoundingClientRect();
  setPos(container, {
    top,
    left,
    right: left + width,
    bottom: top + height,
  });
}

export function setModalModeExpanded(
  container: HTMLElement,
  targetHeight: number,
  targetWidth: number
) {
  container.style.position = 'fixed';

  container.style.height = targetHeight + 'px';
  container.style.width = targetWidth + 'px';

  const dh = targetHeight / 2;
  const dw = targetWidth / 2;

  const screenMiddleH = window.innerHeight / 2;
  const screenMiddleW = window.innerWidth / 2;

  setPos(container, {
    top: screenMiddleH - dh,
    left: screenMiddleW - dw,
    right: screenMiddleW + dw,
    bottom: screenMiddleH + dh,
  });
}
