const versions = {
  "variants": [
    [
      "edu-express",
      "EduExpress"
    ],
    [
      "sandbox",
      "Sandbox"
    ]
  ],
  "locales": [
    "bg-bg",
    "cs-cz",
    "da-dk",
    "de-de",
    "el-gr",
    "en-ae",
    "en-gb",
    "en-hr",
    "en-il",
    "en-ke",
    "en-lk",
    "en-mo",
    "en-mu",
    "en-ng",
    "en-tt",
    "en-us",
    "en-vn",
    "en-xm",
    "es-bo",
    "es-do",
    "es-es",
    "es-la",
    "es-mx",
    "es-na",
    "es-pa",
    "es-py",
    "es-sv",
    "es-uy",
    "et-ee",
    "fi-fi",
    "fil-ph",
    "fr-ca",
    "fr-fr",
    "fr-ma",
    "fr-xm",
    "hr-hr",
    "hu-hu",
    "id-id",
    "it-it",
    "ja-jp",
    "ko-kr",
    "lt-lt",
    "lv-lv",
    "ms-my",
    "nb-no",
    "nl-nl",
    "pl-pl",
    "pt-br",
    "ro-ro",
    "ru-ru",
    "sk-sk",
    "sl-si",
    "sr-rs",
    "sr-sp",
    "sv-se",
    "th-th",
    "tr-tr",
    "uk-ua",
    "vi-vn",
    "zh-cn",
    "zh-hans",
    "zh-hant",
    "zh-mo",
    "zh-tw"
  ],
  "hash": "52b35009",
  "example": "__NAME__.__LOCALE__.52b35009.js"
}; export default versions;